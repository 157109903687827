import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import View from './MakeReviewView'
import { orderReviewFieldNames } from 'components/reviews/constants'
import { openReviewSuccessPopup, postReview } from 'components/reviews/actions'
import { checkServerError } from 'utils'

const mapStateToProps = state => ({
  authToken: state.user.authToken,
})
const mapDispatchToProps = dispatch => ({
  postReview: bindActionCreators(postReview, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserMakeOrderReviewContainer)

function UserMakeOrderReviewContainer({
  orderId,
  authToken,
  postReview,
}) {
  let validationSchema;
  const dispatch = useDispatch()
  const [stateServerError, setServerError] = useState('')

  if (!!authToken && !!orderId) {
    validationSchema = Yup.object().shape({
      [orderReviewFieldNames.RATE]: Yup.string().required('Обязательное поле'),
      [orderReviewFieldNames.REVIEW]: Yup.string().required('Обязательное поле'),
    })
  } else {
    validationSchema = Yup.object().shape({
      [orderReviewFieldNames.NAME]: Yup.string().required('Обязательное поле'),
      [orderReviewFieldNames.REVIEW]: Yup.string().required('Обязательное поле'),
    })
  }

  const formik = useFormik({
    initialValues: {
      [orderReviewFieldNames.NAME]: '',
      [orderReviewFieldNames.RATE]: '',
      [orderReviewFieldNames.REVIEW]: '',
      [orderReviewFieldNames.PHOTOS]: [],
    },

    validationSchema: validationSchema,

    onSubmit: values => {
      const formData = new FormData()

      formData.append(orderReviewFieldNames.RATE, values[orderReviewFieldNames.RATE])
      formData.append(orderReviewFieldNames.NAME, values[orderReviewFieldNames.NAME])
      formData.append(orderReviewFieldNames.REVIEW, values[orderReviewFieldNames.REVIEW])
      values[orderReviewFieldNames.PHOTOS].forEach(file => {
        formData.append(orderReviewFieldNames.PHOTOS, file, file.name)
      })

      setServerError('')

      postReview(orderId, formData)
        .then(response => {
          dispatch(openReviewSuccessPopup())
        })
        .catch(e => {
          checkServerError({
            error: e,
            onMatch: (errorMessage) => {
              setServerError(errorMessage)
            }
          })
        })
    }
  })

  function onRateChangeHandler(value) {
    formik.setFieldValue(orderReviewFieldNames.RATE, value)
  }

  function onChangePhotosHandler(files) {
    formik.setFieldValue(orderReviewFieldNames.PHOTOS, files)
  }

  return <View
    values={formik.values}
    errors={formik.errors}
    touched={formik.touched}
    handleChange={formik.handleChange}
    handleSubmit={formik.handleSubmit}
    onRateChange={onRateChangeHandler}
    onChangePhotos={onChangePhotosHandler}
    serverError={stateServerError}
    isAuth={!!authToken}
    isOrder={!!orderId}
  />
}
