import React, { useState } from 'react'
import { Textarea, Button, InputText, File } from 'components/ui'
import css from './make.module.scss'
import cn from 'classnames'
import { curry } from 'ramda'
import { orderReviewFieldNames } from 'components/reviews/constants'

export default function UserMakeOrderReviewView({
  isAuth,
  isOrder,
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  onRateChange,
  onChangePhotos,
  serverError,
}) {
  return (
    <div className={css.review}>
      <form onSubmit={handleSubmit}>
        {
          (isAuth && isOrder) &&
            <div className={css.ratingScale}>
              <p className="label-2 dark-grey">Общее впечатление</p>
              <Rating
                onChange={onRateChange}
                error={touched[orderReviewFieldNames.RATE] && errors[orderReviewFieldNames.RATE]}
              />
            </div>
        }
        {
          !(isAuth && isOrder) &&
            <div className={css.name}>
              <InputText
                name={orderReviewFieldNames.NAME}
                placeholder='Фамилия Имя Отчество'
                onChange={handleChange}
                value={values[orderReviewFieldNames.NAME]}
                error={touched[orderReviewFieldNames.NAME] && errors[orderReviewFieldNames.NAME]}
              />
            </div>
        }
        <div className={css.textarea}>
          <Textarea
            id="review"
            name={orderReviewFieldNames.REVIEW}
            label="Отзыв"
            placeholder="Ваш отзыв"
            value={values[orderReviewFieldNames.REVIEW]}
            onChange={handleChange}
            error={touched[orderReviewFieldNames.REVIEW] && errors[orderReviewFieldNames.REVIEW]}
          />
        </div>

        <div>
          <File
            onChange={onChangePhotos}
            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
            maxFileSize={5242880}
          />
        </div>

        {
          serverError &&
          <React.Fragment>
            <p className="caption red">{serverError}</p>
            <p><br /></p>
          </React.Fragment>
        }

        <div className={css.buttonWrap}>
          <Button 
            className="form-button" 
            text={'Отправить отзыв'} 
            type="submit"
          />
        </div>
      </form>
    </div>
  )
}


function Rating({
  name = 'rate',
  onChange,
  error
}) {
  const ratings = [
    {
      label: '😟',
      value: '1'
    },
    {
      label: '😕',
      value: '2'
    },
    {
      label: '😐',
      value: '3'
    },
    {
      label: '😊',
      value: '4'
    },
    {
      label: '😃',
      value: '5'
    }
  ]

  const [value, setValue] = useState('')

  const onClickCurry = curry((selectedValue, event) => {
    setValue(selectedValue)
    onChange(selectedValue)
  })

  return (
    <div>
      <input name={name} onChange={onChange} value={value} hidden={true} />
      <ul>
        {ratings.map((rating, index) => (
          <li
            key={`${name}-${index}`}
            className={cn((rating.value === value) && css.Active)}
          >
            <button
              className='pure'
              tabIndex='0'
              onClick={onClickCurry(rating.value)}
              onKeyDown={() => {}}
            >
              {rating.label}
            </button>
          </li>
        ))}
      </ul>
      {error &&
        <p className={'input-text__error caption red'}>{error}</p>
      }
    </div>
  )
}
